import html2canvas from 'html2canvas'

const dataURLToBlob = (url) => {
  let arr = url.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

/**
 * @functionName saveImage
 * @description div保存为图片
 * @param {object} config
 * @param {string} config.backgroundColor 背景颜色 为null是透明
 * @param {number} config.dpi 按屏幕像素比增加像素
 * @param {number} config.scrollX 如果左边多个白边 设置该偏移-3 或者更多
 * @param {boolean} config.useCORS 是否使用CORS从服务器加载图像 !!!
 * @param {boolean} config.allowTaint 是否允许跨域图像污染画布 !!!
 */
export const saveImage = (config = {}) => {
  const { id, scroll, name, scale = 2, backgroundColor = '#fff', dpi = 2, scrollX = -3, scrollY = -10 } = config
  const canvasID = document.getElementById(id)
  const scrollDom = document.getElementById(scroll)
  if (!canvasID) return
  if(scrollDom) scrollDom.setAttribute('style', 'height: auto')
  // dom 宽/ 高 / 放大倍数 (个相当于清晰度 调大一点更清晰一点)
  const [width, height] = [canvasID.offsetWidth, canvasID.offsetHeight]
  const newConfig = { width, height, backgroundColor, dpi: window.devicePixelRatio * dpi, scale, X: 0, Y: 0, scrollX, scrollY, useCORS: true, logging: true, allowTaint: true }
  const a = document.createElement('a')
  html2canvas(canvasID, newConfig).then(canvas => {
    // const url = canvas.toDataURL()
    const dom = document.body.appendChild(canvas)
    dom.style.display = 'none'
    a.style.display = 'none'
    document.body.removeChild(dom)
    const blob = dataURLToBlob(dom.toDataURL('image/png'))
    a.setAttribute('href', URL.createObjectURL(blob))
    //这块是保存图片操作  可以设置保存的图片的信息
    a.setAttribute('download', `${name || Date.now()}.png`)
    document.body.appendChild(a)
    // const event = new MouseEvent('click')
    // a.dispatchEvent(event) // 触发a的单击事件 即可完成下载
    a.click()
    URL.revokeObjectURL(blob)
    document.body.removeChild(a)
    if(scrollDom) scrollDom.removeAttribute('style')
  })
}
